import { memo, useState } from "react";
import styled from "styled-components";
import IPPONLogo from "./ippon_logo_02.png"


const SignIn = memo(() => {

    const username = "Blanchee";
    const password = "Blanchee";

    const [inputUsername, setInputUsername] = useState('');
    const [inputPassword, setInputPassword] = useState('');
    const [inputtingText, setInputtingText] = useState('');
    const [signinSuccess, setSigninSuccess] = useState(false);
    const [signinFailure, setSigninFailure] = useState(false);

    const executeSignIn = () => {
        if (inputUsername === username && inputPassword === password){
            setSigninSuccess(true);
            setSigninFailure(false);
        } else {
            setSigninSuccess(false);
            setSigninFailure(true);
        }
      };


      return (
        <SOverlay>
            <SSigninWindow>
                <SContainerOfLogo>
                    <SLogo src={IPPONLogo} />
                </SContainerOfLogo>    
                <SSignin>
                    <SUserInputArea inputting={inputtingText==="username"} >
                        <SUserInput type="text" value={inputUsername} placeholder={"ID"} onChange={event=>{setInputUsername(event.target.value)}} onKeyDown={event => event.key === 'Enter'?executeSignIn():null} onFocus={() => {setSigninSuccess(false); setSigninFailure(false); setInputtingText("username")}} onBlur={() => setInputtingText("")}/>
                    </SUserInputArea>
                    <SUserInputArea inputting={inputtingText==="password"} >
                        <SUserInput type="password" value={inputPassword} placeholder={"パスワード"} onChange={event=>{setInputPassword(event.target.value)}} onKeyDown={event => event.key === 'Enter'?executeSignIn():null} onFocus={() => {setSigninSuccess(false); setSigninFailure(false); setInputtingText("password")}} onBlur={() => setInputtingText("")}/>
                    </SUserInputArea>
                    {signinSuccess&&<SMessageOfSigninSuccess signinSuccess={signinSuccess} >You could sign in.</SMessageOfSigninSuccess>}
                    {signinFailure&&<SMessageOfSigninFailure signinFailure={signinFailure} >You could not sign in.</SMessageOfSigninFailure>}
                    <SSignInButton onClick={executeSignIn}>次へ</SSignInButton>
                    <SDidYouForgetPassword>パスワードをお忘れですか？</SDidYouForgetPassword>
                </SSignin>
            </SSigninWindow>
        </SOverlay>          
      )

})

export { SignIn }

const SOverlay = styled.div`
/*　画面全体を覆う設定　*/    
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:#F7FBFD;            
    /*　画面の中央に要素を表示させる設定　*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
`;

const SSigninWindow = styled.div`
    z-index:2;
    width: 420px;
    max-height: 543px;
    background-color:#FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    //flex: 1 1 0;
`;

const SContainerOfLogo = styled.div`
    margin-top: 37px;
    margin-bottom: 18px;
    width: 258px;
    height: 82px; 
    background: rgba(255,255,255,1);
    display: flex;
    align-items : center;
`;

const SLogo  = styled.img`
    max-width: 100%;
    height: auto;
    display: block;
`;

const SSignin = styled.div`
    width: 100%;
    border-radius: 15px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    background-color:#FFFFFF;
    margin-bottom:37px;
`;

const SUserInputArea = styled.div`
    width: 324px;
    height: 46px;
    border-radius: 20px;
    border: 1px solid #0B9DBD;
    margin:8px;
    display:flex;
    align-items: center;
    border: ${({inputting})=>inputting? "2px solid #0B9DBD": "1px solid #0B9DBD"};
    margin: ${({inputting})=>inputting? "7px": "8px"};
`;

const SUserInput = styled.input`
    display: block;
    width: 290px;
    height: 23px;
    margin-left:17px;
    padding:0;
    border:none;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    &::placeholder {
        color: #989898;
    }
    &:focus{
        outline:none;
    }
`;

const SSignInButton = styled.button`
    display: inline-block;
    width: 92px;
    height: 36px;
    border-radius: 20px;
    background: #0B9DBD;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #FFFFFF;
    border:none;
    margin:8px;
    cursor: pointer;
    margin-top:25px;  
`;

const SMessageOfSigninSuccess = styled.p`
    height: 18px;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #0B9DBD;
    opacity:${({signinSuccess})=>signinSuccess?"1":"0"};
`;

const SMessageOfSigninFailure = styled.p`
    height: 18px;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #DA384C;
    opacity:${({signinFailure})=>signinFailure?"1":"0"};
`;

const SDidYouForgetPassword = styled.button`
    display: inline-block;
    width: 208px;
    height: 23px;
    border-radius: 20px;
    background:rgba(0,0,0,0);
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #989898;
    border:none;
    padding:0;
    margin-top:8px;
    margin-bottom:23.5px;
    cursor: pointer;
`;