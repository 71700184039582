import logo from './logo.svg';
import './App.css';

import {SignIn} from './SignIn'

function App() {
  return (
    <SignIn />
  );
}

export default App;
